import controllerLoader from './parts_setup/controller_loader';
import fadeIn from './utils/fadein';
import fadeOut from './utils/fadeout';
import scrollToTop from './utils/scroll_to_top';

require('es6-promise').polyfill();

// xxx.js.erbから呼び出せるように、グローバルにする
window.scrollToTop = scrollToTop;

// トップメニューのAffix
const setupTopMenuAffix = () => {
  new window.bootstrapNative.Affix(document.getElementById('navbar'), { // eslint-disable-line no-new
    offsetTop: 40,
  });
};

// ページ内遷移の場合、navbar-toggleが閉じないので対応（スマホ表示時）
const setupNavbarToggle = () => {
  Array.prototype.slice.call(document.querySelectorAll('.nav a')).forEach((element) => {
    element.addEventListener('click', () => {
      const navbarToggleElement = document.getElementById('navbar-toggle');
      if (navbarToggleElement.style.display !== 'none') {
        navbarToggleElement.click();
      }
    });
  });
};

// 画面表示時の共通処理
window.initPage = (controllerName, actionName) => {
  // コントローラごとのモジュールをimport
  if (actionName === 'new' || actionName === 'create' || actionName === 'edit' || actionName === 'update') {
    controllerLoader(controllerName, 'form'); // formは各アクション共通
  } else {
    controllerLoader(controllerName, actionName);
  }
};

// 画面表示時・turbolinksで遷移時に呼び出される処理
document.addEventListener('turbolinks:load', () => {
  BSN.initCallback();

  fadeIn(document.getElementById('main')); // メイン部分をフェードイン

  setupTopMenuAffix(); // トップメニューのAffix
  setupNavbarToggle();

  const { body } = document;
  if (body == null) return;

  const controllerName = body.getAttribute('data-controller_name');
  const actionName = body.getAttribute('data-action_name');

  window.initPage(controllerName, actionName);
});

// Turbolinksによる遷移が始まる直前
document.addEventListener('turbolinks:before-visit', () => {
  fadeOut(document.getElementById('main')); // メイン部分をフェードアウト
});
