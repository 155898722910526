import Turbolinks from 'turbolinks';

window.Rails = require('rails-ujs');
window.bootstrapNative = require('bootstrap.native');
require('bootstrap.native/dist/polyfill.min.js');

window.algoliasearch = require('algoliasearch');
window.algoliasearchHelper = require('algoliasearch-helper');
window.Hogan = require('hogan.js');

Rails.start();
Turbolinks.start();

import '../javascripts/front';
import '../stylesheets/front';

require.context('../images/', true, /\.(gif|jpg|png|svg)$/i);
