// package.jsonで、eslint対象外にしている
// なぜかこのファイルで、TypeError: Cannot read property 'value' of nullになってしまい原因不明のため

// コントローラごとのモジュールをimport
export default function (controllerName, actionName) {
  import(`../controllers/${controllerName}.js`)
    .then((module) => {
      if (module[actionName] !== undefined) module[actionName]();
    })
    .catch(() => {
      // コントローラごとのモジュールが存在しない場合は何もしない
    });
}
